import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from "gatsby";
import Layout from '../../components/layout'
import Seo from '../../components/seo';

export const query = graphql`
  query PostQuery($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      title
      body
      posted_at
    }
  }
`;

export default function Post({ data }) {
  const { title, body, posted_at } = data.strapiPost
  const postedDate = new Date(posted_at)
  const postedDateString = postedDate.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })

  return (
    <Layout>
      <Seo title={title} />
      <h2>{title}</h2>
      <p className={"text-sm text-note"}>By Nic Schlueter | <span>{postedDateString}</span></p>
      <ReactMarkdown className={"blogContent mt-6"}>{body}</ReactMarkdown>
    </Layout>
  )
}
